import React from "react"
import { DrinkCategory } from "../../../../types"

import "./DrinkCategoryComponent.less"

interface DrinkCategoryComponentProps {
  drinkCategory: DrinkCategory
}

export default function DrinkCategoryComponent({
  drinkCategory,
}: DrinkCategoryComponentProps) {
  return (
    <div className="drink-category">
      <div className="drink-head">
        <h3>{drinkCategory.titre}</h3>
        {drinkCategory.quantity && (
          <span className="drink-quantity">, {drinkCategory.quantity}</span>
        )}
        {drinkCategory.price && (
          <span className="drink-price">{drinkCategory.price}&euro;</span>
        )}
      </div>
      {drinkCategory.description && (
        <span className="drink-category-description">
          {drinkCategory.description}
        </span>
      )}
      {drinkCategory.drinks && (
        <ul className="drinks-list">
          {drinkCategory.drinks.map(drink => (
            <li key={drink.contentful_id}>
              <span className="drink-name">{drink.name.name}</span>
              {drink.description && (
                <span className="drink-description">, {drink.description}</span>
              )}
              {drink.price && (
                <span className="drink-price">{drink.price}&euro;</span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
