import React, { CSSProperties, ReactNode } from "react"

import "./Cadre.less"

interface CadreProps {
  children: ReactNode
  color: string
  widthX: number
  widthY: number
  ratioX: number
  ratioY: number
  paddingContent: number
  widthLargeLine: number
  widthLightLine: number
  displayDivider?: boolean
  className?: string
  style?: CSSProperties
  styleContent?: CSSProperties
}

export default function Cadre({
  children,
  color,
  widthX,
  widthY,
  widthLargeLine,
  widthLightLine,
  ratioX,
  ratioY,
  className,
  style,
  styleContent,
  displayDivider,
}: CadreProps) {
  const cornerConfig: CSSProperties = {
    borderColor: color,
    borderWidth: widthLightLine,
    height: `${ratioY * widthY}vw`,
    width: `${ratioX * widthX}vw`,
  }

  const bandeXConfig: CSSProperties = {
    borderColor: color,
    borderWidth: `${widthLargeLine}px`,
    height: `calc(100% - ${widthY * 2}vw - ${2 * widthLargeLine}px)`,
    width: `calc(${widthX}vw - ${2 * widthLargeLine}px)`,
    top: `${widthY}vw`,
  }

  const bandeYConfig: CSSProperties = {
    borderColor: color,
    borderWidth: `${widthLargeLine}px`,
    width: `calc(100% - ${widthX * 2}vw)`,
    height: `${widthY}vw`,
    left: `calc(${widthX}vw - ${widthLargeLine}px)`,
  }

  const dividerConfig: CSSProperties = {
    borderColor: color,
    borderWidth: `${widthLightLine}px`,
    height: `calc(100% - ${2 * widthY * ratioY}vw - ${2 * widthLightLine}px)`,
    top: `calc(${ratioY * widthY}vw + ${widthLightLine}px)`,
  }

  return (
    <div
      className={"cadre"}
      style={{ ...style, padding: `0 ${widthX * ratioX}vw` }}
    >
      <div className="corner top-left" style={cornerConfig}></div>
      <div className="corner top-right" style={cornerConfig}></div>
      <div className="corner bottom-left" style={cornerConfig}></div>
      <div className="corner bottom-right" style={cornerConfig}></div>
      <div className="bande left" style={bandeXConfig}></div>
      <div className="bande right" style={bandeXConfig}></div>
      <div className="bande top" style={bandeYConfig}></div>
      <div className="bande bottom" style={bandeYConfig}></div>
      {(displayDivider === undefined || displayDivider === true) && (
        <>
          <div
            className="divider left"
            style={{
              ...dividerConfig,
              left: `calc(${ratioX * widthX}vw + ${widthLightLine}px)`,
            }}
          ></div>
          <div
            className="divider right"
            style={{
              ...dividerConfig,
              right: `calc(${ratioX * widthX}vw + ${widthLightLine}px)`,
            }}
          ></div>
        </>
      )}
      <div className={`content ` + className} style={styleContent}>
        {" "}
        {children}
      </div>
    </div>
  )
}
