import React from "react"
import { MenuBurger } from "../../../../types"
import BurgerComponent from "./BurgerComponent"

import "./MenuBurgerComponent.less"

interface MenuBurgerProps {
  menu: MenuBurger
}

export default function ({ menu }: MenuBurgerProps) {
  return (
    <div className="menu-burger">
      <div className="menu-burger-title">
        <div className="divider-burger-menu"></div>
        <div className="content">{menu.title}</div>
        <div className="divider-burger-menu"></div>
      </div>
      {menu.burgers.map(burger => (
        <BurgerComponent key={burger.contentful_id} burger={burger} />
      ))}
      {menu.asterisques && (
        <div className="asterisque">
          <span>{menu.asterisques}</span>
        </div>
      )}
    </div>
  )
}
