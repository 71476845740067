import React from "react"
import { Burger } from "../../../../types"

import "./BurgerComponent.less"

interface BurgerProps {
  burger: Burger
}

export default function ({ burger }: BurgerProps) {
  return (
    <div className="burger-item">
      <h3>{burger.name}</h3>
      <span>{burger.subtitle}</span>
      <p>{burger.description.description}</p>
    </div>
  )
}
