import React from "react"
import {
  Album,
  HorairesOuverture,
  Image,
  Livraison,
  AbstractCarteCategory,
  MenuBurger,
  Supplement,
  DrinkCategory,
  Dessert,
} from "./types"

import MenuBurgerComponent from "./components/Menu/Categories/MenuBurgerComponent/MenuBurgerComponent"
import SupplementComponent from "./components/Menu/Categories/SupplementComponent/SupplementComponent"
import DrinkCategoryComponent from "./components/Menu/Categories/DrinkCategoryComponent/DrinkCategoryComponent"
import DessertComponent from "./components/Menu/Categories/DessertComponent/DessertComponent"

export const getUsedHoraires = (
  horaires: { node: HorairesOuverture }[]
): HorairesOuverture | undefined => {
  return (
    horaires.filter(
      horaire => horaire.node.contentful_id === process.env.GATSBY_HORAIRES
    )[0]?.node ?? undefined
  )
}

export const findAssetById = (
  images: Image[],
  id: string
): Image | undefined => {
  return images.filter(image => image.contentful_id === id)[0] ?? undefined
}

export const findAlbumById = (
  albums: Album[],
  id: string
): Album | undefined => {
  return albums.filter(album => album.contentful_id === id)[0] ?? undefined
}

export const findLivraisonById = (
  livraisons: Livraison[],
  id: string
): Livraison | undefined => {
  return (
    livraisons.filter(livraison => livraison.contentful_id === id)[0] ??
    undefined
  )
}

export const buildCategoryMenuElement = (
  item: AbstractCarteCategory
): React.ReactNode => {
  switch (item.internal.type) {
    case "ContentfulMenuBurger":
      return (
        <MenuBurgerComponent
          key={item.contentful_id}
          menu={item as MenuBurger}
        />
      )
    case "ContentfulSupplement":
      return (
        <SupplementComponent
          key={item.contentful_id}
          supplement={item as Supplement}
        />
      )
    case "ContentfulDrinksCategory":
      return (
        <DrinkCategoryComponent
          key={item.contentful_id}
          drinkCategory={item as DrinkCategory}
        />
      )
    case "ContentfulDessert":
      return (
        <DessertComponent key={item.contentful_id} dessert={item as Dessert} />
      )
  }

  throw new Error(
    `Impossible de trouver un composant de catégory de menu pour ce type "${item.internal.type}"`
  )
}
