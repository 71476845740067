import React from "react"
import { Dessert } from "../../../../types"

import "./DessertComponent.less"

interface DessertComponentProps {
  dessert: Dessert
}

export default function DessertComponent({ dessert }: DessertComponentProps) {
  return (
    <div className="category-dessert">
      <div className="dessert-head">
        <h3>{dessert.name}</h3>
        <span className="price">{dessert.price}&euro;</span>
      </div>
      <div className="body">
        <span className="subtitle">{dessert.subtitle}</span>
        <span className="dessert-description">
          {dessert.childContentfulDessertDescriptionTextNode.description}
        </span>
      </div>
    </div>
  )
}
