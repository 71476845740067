import React from "react"
import { Supplement } from "../../../../types"

import "./SupplementComponent.less"

interface SupplementComponentProps {
  supplement: Supplement
}

export default function SupplementComponent({
  supplement,
}: SupplementComponentProps) {
  return (
    <div className="menu-supplement">
      <div className="supplement-head">
        <h3>{supplement.title}</h3>
        <span className="price">{supplement.price}&euro;</span>
      </div>
      <p>
        {supplement.childContentfulSupplementDescriptionTextNode.description}
      </p>
      {supplement.supplements !== undefined && supplement.supplements !== null && (
        <div className="sub-supplement">
          {supplement.supplements.map(subSup => (
            <SupplementComponent
              key={subSup.contentful_id}
              supplement={subSup}
            />
          ))}
        </div>
      )}
    </div>
  )
}
